<template>
  <div>
    <div
      class="card-filter-section d-flex align-items-center justify-content-between mt-0"
    >
      <p class="task-timesheet m-0">
        Groups
      </p>
      <div class="role-buttons d-flex flex-wrap align-items-center">
        <!-- add group button  -->
        <table-button
          v-b-toggle.addgroup
          btn-name="add group"
        />
        <Help
          v-show="$store.state.app.userManualList"
          code="Group"
        />
      </div>
    </div>
    <!-- filter-start -->
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <p class="filter-title">
            Filters:
          </p>

          <div class="">
            <custom-dropdown
              v-model="is_active"
              :options="isActiveList"
              :search-box="false"
              :hide-close="true"
              label="Active"
              icon="EditIcon"
            />
          </div>
          <option-drop-down v-model="perPage" />
        </div>
        <div class="d-flex align-items-center">
          <b-input-group
            class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px"
          >
            <b-form-input
              v-model="debouncedSearch"
              placeholder="Search by Group Name"
            />

            <b-input-group-append is-text>
              <feather-icon
                v-if="debouncedSearch"
                icon="XIcon"
                @click="debouncedSearch = null,search = null"
              />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <div class="ml-1">
            <button
              class="apply-filter filterRedesign mr-1"
              @click="applyFilter"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- filter-end -->
    <div class="horizontal-line table">
      <TableLoader
        v-if="loading"
        :fields="fields"
      />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start-->
          <b-table
            responsive
            :items="items"
            :fields="fields"
            show-empty
            class="scrollable-element tableDesigns mastertblColor non-project-table"
          >
            <template #cell(name)="data">
              <div class="media centeralign">
                <div class="media-aside align-self-center">
                  <b-avatar
                    :text="data.item.name.charAt(0)"
                    :style="{ 'background-color': '#' + data.item.color }"
                    size="40"
                  />
                </div>
                <div>
                  <p
                    v-b-tooltip.hover.top="data.item.name"
                    class="custom-project"
                    :style="{ color: '#' + data.item.color }"
                  >
                    {{ data.item.name }}
                  </p>
                </div>
              </div>
            </template>

            <template #cell(is_active)="data">
              <b-form-checkbox
                v-model="data.item.is_active"
                class="custom-control-success"
                name="check-button"
                size="md"
                switch
                @change="changeStatus(data.item, data.item.is_active)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </template>

            <template #cell(members)="data">
              <div
                v-for="(user,index) in data.item.users"
                :key="index"
                class="groupnameSepicon"
              >
                {{ user.full_name }}
              </div>
            </template>

            <template #cell(action)="data">
              <span>
                <feather-icon
                  v-b-tooltip.hover.top="'Edit'"
                  icon="EditIcon"
                  role="button"
                  class="mr-1 text-dark"
                  size="18"
                  @click="edit(data.item)"
                />
                <feather-icon
                  v-b-tooltip.hover.top="'Delete'"
                  icon="Trash2Icon"
                  role="button"
                  class="text-danger"
                  size="18"
                  @click="deleteConfirmation(data.item.id)"
                />
              </span>
            </template>
          </b-table>
          <!-- data table complete -->
          <img
            v-if="totalCount == 0"
            src="../../assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
          <!-- data table complete -->
        </b-col>
      </b-row>
      <!-- pagination section -->
      <Pagination
        v-if="totalCount>0 && !loading"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section complete here -->
      <!-- add/edit group from start-->
      <group-table-form @groupList="getGroupData" />
    </div>
  </div>
</template>

<script>
import TableLoader from '@/components/loaders/table-loading.vue'
import TableButton from '@/@core/components/buttons/TableButton.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import GroupTableForm from '@/@core/components/table-from/GroupTableForm.vue'
import { eventBus } from '@/main'
import debounce from 'lodash/debounce'

export default {
  name: 'GroupTable',
  components: {
    TableLoader,
    TableButton,
    GroupTableForm,
    customDropdown,
  },
  data() {
    return {
      search: null,

      id: 'team',
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      loading: true,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      is_active: 'active',
      fields: [
        {
          key: 'name',
          label: 'Group Name',
          sortable: true,
          thStyle: { width: '274px' },
          tdStyle: { width: '274px' },
        },
        {
          key: 'members',
          label: 'Group Members',
          tdClass: 'groupnameSep',
        },
        {
          key: 'is_active',
          label: 'Active',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          tdStyle: { width: '120px' },
        },
      ],
      items: [],
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.getGroupData()
      }, 750),
    },
  },

  watch: {
    perPage() {
      if (this.currentPage == 1) {
        this.getGroupData()
      } else {
        this.currentPage = 1
      }
    },
    /**
     * @description on page number change Call getGroupData()
     */
    currentPage: {
      handler(value) {
        if (value) {
          this.getGroupData()
        }
      },
    },

  },
 

  mounted() {
    eventBus.$on('groupList', data => {
      if (data) {
        this.currentPage = 1
      }
    })
    this.getGroupData()
  },
  methods: {
    /**
     * @description Get Group List
     * @method POST
     * @returns items (variable)(Group list)
     */
    async getGroupData(page) {
      this.currentPage = page || this.currentPage
      const input = {
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: this.sortBy,
        sort_order: this.sortDesc ? 'desc' : 'asc',
        is_active: this.is_active && this.is_active !== 'all_data'
          ? this.is_active === 'active'
          : '',
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/group',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.items = data.groups

        this.totalCount = data.count
      }
      this.loading = false
    },
    /**
     * @description For Edit group pass data of group to group table form
     * @emits edit-client
     */
    edit(data) {
      // to emit the event
      eventBus.$emit('edit-group', data)
    },
    /**
     * @description Change Group status
     * @param {index}  array index
     * @param {value}  value (true, false)
     * @returns if change then call updatestatus method
     */
    async changeStatus(index) {
      const group = this.items.find(group => group == index)
      if (!group) {
        return false
      }
      const label = group.is_active ? 'Active' : 'Deactive'
      const title = `Are you sure ${label} Group?`
      const conformation = await this.conformationAlert(false, title, label)

      if (conformation.isConfirmed == true) {
        this.updateStatus(group)
      } else {
        this.items = []
        this.getGroupData()
      }
    },
    /**
     * @description Update Group status
     * @method PUT
     * @param {data} data(Group Infomation in Array)
     * @returns Group Status update
     */
    async updateStatus(data) {
      const response = await this.getHTTPPutResponse(
        `admin/master/group/status/${data.id}?active=${data.is_active ? 1 : 0}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.getGroupData()
      }
    },
    /**
     * @description confirmation to delete Group
     * @param {id} Group id
     * @returns if conform to delete call deleteGroup function
     */
    async deleteConfirmation(id) {
      const value = await this.conformationAlert(true)
      if (value.isConfirmed === true) {
        this.deleteGroup(id)
      }
    },
    /**
     * @description delete Group
     * @method DELETE
     * @param {id}  team id
     */
    async deleteGroup(id) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/group/delete/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.getGroupData()
      }
    },
    applyFilter() {
      this.getGroupData()
    },
    clearFilter() {
      this.search = null
      this.perPage = 10
      this.is_active = 'active'
      this.getGroupData()
    },
  },
}
</script>
<style lang="scss" scoped>
@import "./../../assets/scss/component-css/masterSettingFilter.scss";
</style>
